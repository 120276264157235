export default [
  {
    title: 'Biz Cards',
    icon: 'HomeIcon',
    // tag: '2',
    // tagVariant: 'light-warning',
    children: [
      {
        title: 'Manage',
        route: 'business-cards-list',
      },
      {
        title: 'Add Card Type',
        route: 'business-cards-types-list',
      },
      // {
      //   title: 'Networking',
      //   route: 'dashboard-analytics',
      // },
    ],
  },
]
