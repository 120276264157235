<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <span>Copyright &copy;</span>
      <span>{{ new Date().getFullYear() }} </span>
      <b-link
        href="https://dashboard.steadylah-network.com"
        target="_blank"
        rel="nofollow"
      >SteadyLah Network</b-link>
      <span class="d-sm-inline-block">, All rights Reserved | </span>
      <b-link
        href="/terms"
        target="_blank"
        rel="nofollow"
      >
        Terms of Service</b-link>
      <span>,</span>
      <b-link
        href="/privacy"
        target="_blank"
        rel="nofollow"
      >
        Privacy Policy</b-link>
      <span> and</span>
      <b-link
        href="/pdpa"
        target="_blank"
        rel="nofollow"
      >
        Data Protection Policy</b-link>
    </span>

    <span
      class="float-md-right d-none d-md-block"
    >Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
